import './ValidationCard.scss';
import urljoin from 'url-join';
import React, { Component } from 'react';
import Helpers from '../helpers';
//import Base64 from 'base-64';
import Header from './Header/Header';
import FileList from './Files/FileList';
import Dropzone from './Dropzone/Dropzone';
import ResetDropzoneButton from './Dropzone/Buttons/ResetButton';
import ValidateDropzoneButton from './Dropzone/Buttons/ValidateButton';
import ClearDropzoneButton from './Dropzone/Buttons/ClearButton';
import RefreshSchemasButton from './Dropzone/Buttons/RefreshSchemasButton'
import RawReport from './Report/RawReport';
import Gear from './Gear';
import FooterLogo from '../images/footer-logo.png';
import AlertDisplay from './Alert/AlertDisplay';

// HL7 NIST IGAMT
import HL7NISTIGAMTResponseMapper from '../helpers/hl7_nist_igamt-responseMapper';
import HL7NISTIGAMTValidationServicesReport from './Report/ValidationServices/HL7NISTIGAMTReport';

//CSV Flat File
import CSVFlatFileResponseMapper from '../helpers/csv_flatfile-responseMapper';
import CSVValidationServicesReport from './Report/ValidationServices/CSVFlatFileReport';

//eICR
import EICRResponseMapper from '../helpers/eicr-responseMapper';
import EICRReport from './Report/ValidationServices/EICRReport';

//Default
import DefaultResponseMapper from '../helpers/default-responseMapper';
import DefaultReport from './Report/ValidationServices/DefaultReport';

class ValidationCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            option: 1,
            fileItems: {},
            processingFiles: [],
            xmlDocs: {},
            uploading: false,
            successfulUpload: false,
            windowWidth: 0,
            windowHeight: 0,
            cardFaceHeight: 0,
            reports: {},
            showReports: [],
            validatorsDetails: [],
            validatorsSchemaLists: [],
            validator: '',
            schemaDetails: [],
            schema: '',
            show_classifications: {
                check_spec_errors: true,
                check_alerts: false,
                check_informational: false,
                check_affirmatives: false,
                check_raw_data: false
            },
            showAdvanced: false,
            alertDetails: {}
        };

        this.updateValidator = this.updateValidator.bind(this);
        this.updateSchema = this.updateSchema.bind(this);
        this.saveToLocalStorage = this.saveToLocalStorage.bind(this);
        this.fetchSchemaLists = this.fetchSchemaLists.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.getBase64 = this.getBase64.bind(this);
        this.updateReports = this.updateReports.bind(this);
        this.toggleReport = this.toggleReport.bind(this);
        this.resetDropzone = this.resetDropzone.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.setShowAdvanced = this.setShowAdvanced.bind(this);
        this.toggleShowClassifications = this.toggleShowClassifications.bind(this);
        this.refreshSchemas = this.refreshSchemas.bind(this);
}

    async componentDidMount() {
        const ele = document.getElementById('ipl-progress-indicator');
        try {
            this.updateWindowDimensions();
            window.addEventListener('resize', this.updateWindowDimensions);
            await this.fetchValidatorsDetails();
            await this.fetchSchemaLists();
            ele.classList.add('available');
        } catch (error) {
            this.state.alertDetails.header = 'ERROR';
            this.state.alertDetails.message = 'Error: ' + error;
            console.log('CATCH COMPONENT DID NOT MOUNT: ', this.state.alertDetails);
            ele.classList.add('available');
        }
    }

    getBase64(file, onLoadCallback) {
      return new Promise(function (resolve, reject) {
          var reader = new FileReader();
          reader.onload = function () { resolve(reader.result); };
          reader.onerror = reject;
          reader.readAsDataURL(file);
      });
    }

    async fetchValidatorsDetails() {
        try {
            //TODO - DONT LEAVE THIS HARDCODED LINK IN AFTER TESTING
            //const res = await fetch(urljoin('https://qvl09u5jd8.execute-api.us-east-1.amazonaws.com/dev/validators'), {
            const res = await fetch(urljoin('https://api.gvt.aimsplatform.org/routes'), {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                  'Content-Type': 'application/json',
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  'Access-Control-Allow-Origin': '*'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                //body: "{}" // body data type must match "Content-Type" header
              });
            const { validators } = await res.json();
            return this.setState({ validatorsDetails: validators });
        } catch(e) {
            throw(e);
        }
    }

  setShowAdvanced(showAdvanced) {
    console.log('showAdvanced', showAdvanced)
    this.setState({ showAdvanced }, () => {
      this.saveToLocalStorage();
    })
  }

  toggleShowClassifications({ target: { name, checked } }) {
    console.log('toggleShowClassifications|name:' + name);
    console.log('toggleShowClassifications|checked:' + checked);

    const { show_classifications } = this.state;
    this.setState(
      {
        show_classifications: {
          ...show_classifications,
          [name]: checked,
        },
      },
      this.saveToLocalStorage
    );

  }

  async refreshSchemas() {
    // Set localstorage savedValidatorsSchemaLists to null and refresh page
    this.state.validatorsSchemaLists = [];
    window.localStorage.setItem('savedValidatorsSchemaLists',null);
    window.location.reload();
  }

  async fetchSchemaLists() {

    // Attempt to retrieve from local storage
    const savedValidatorsSchemaLists = JSON.parse(window.localStorage.getItem('savedValidatorsSchemaLists'));
    if(savedValidatorsSchemaLists === null || !savedValidatorsSchemaLists) {
      return await this.fetchSchemaListsFromURL();
    } else {
      this.state.validatorsSchemaLists = savedValidatorsSchemaLists.savedValidatorsSchemaLists;
      console.log('localstorage used to return:',this.state.validatorsSchemaLists);
      if(savedValidatorsSchemaLists && savedValidatorsSchemaLists.lastSync + 21600000 <= new Date()) {
        console.log('Updating savedValidatorsSchemaLists in the background because lastSync is older than 6 hours.')
        // Dont await here
        this.fetchSchemaListsFromURL()
      }
      return this.state.validatorsSchemaLists;
    }
  }
  
  async fetchSchemaListsFromURL() {

    const _validatorsSchemaLists = [];
    for (let i = 0; i < this.state.validatorsDetails.length; i++) {

      const res = await fetch(urljoin(this.state.validatorsDetails[i].url, 'manifest', 'details'));
      const { body } = await res.json();
      const schemaList = JSON.parse(body).schemaList;
      const activeSchemaList = [];

      for (let j = 0; j < schemaList.length; j++) {
        const key = Object.keys(schemaList[j]);
        // Only push active ones that have properties
        if (schemaList[j][key].properties !== null && schemaList[j][key].properties.active === true) {
          activeSchemaList.push(schemaList[j]);
        }
        if (j === schemaList.length - 1) {
          const schemaListDetails = { name: this.state.validatorsDetails[i].name, schemaList: activeSchemaList }
          _validatorsSchemaLists.push(schemaListDetails);
          //this.state.validatorsSchemaLists.push(schemaListDetails);
        }
      }

      if (i === this.state.validatorsDetails.length - 1) {
        console.log('FINAL|fetchSchemaLists|validatorsSchemaLists:', _validatorsSchemaLists);
        const data = {
          lastSync: Date.now(),
          savedValidatorsSchemaLists: _validatorsSchemaLists
        }
        // Save validatorsSchemaLists to localstorage
        window.localStorage.setItem('savedValidatorsSchemaLists', JSON.stringify(data));
        this.state.validatorsSchemaLists = _validatorsSchemaLists;
        return this.state.validatorsSchemaLists;
      }

    }

  } 

  getSchemaKey() {
    return this.fetchSchemaList().getKey();
  }

  updateWindowDimensions() {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
    const h = this.cardFace.clientHeight;
    this.setState({ cardFaceHeight: h });
  }

  cardClassName() {
    const numFiles = Object.keys(this.state.fileItems).length;
    var fileListHeight = 75 * numFiles + 20 * (numFiles - 1) - 230;
    var resize =
      this.state.cardFaceHeight + fileListHeight > this.state.windowHeight
        ? true
        : false;

    var className = "Card";
    if (Object.keys(this.state.fileItems).length > 0) {
      className += " Card-Files";
    }

    if (resize || this.state.showReports.length > 0) {
      className += " Card-Files-X";
    }
    return className;
  }

  updateValidator(validator) {
    this.setState({
      validator: validator,
      show_classifications: {
        check_spec_errors: true,
        check_alerts: false,
        check_informational: false,
        check_affirmatives: false,
        check_raw_data: false
      }
    }, () => {
      this.saveToLocalStorage();
    });

  }

  updateSchema(schema) {
    this.setState({ schema }, this.saveToLocalStorage);
  };

  onFilesAdded(files) {
    var fileItems = this.state.fileItems;
    files.forEach((file) => {
      fileItems[Helpers.generateUUID()] = file;
    });

    this.setState((prevState) => ({
      fileItems: fileItems,
    }));
  }

  async uploadFiles() {

    this.state.validatorsSchemaLists.forEach((item, index) => {

      if (item.name === this.state.validator) {

        console.log('uploadFiles|validator:', this.state.validator);
        console.log('uploadFiles|validatorsDetails:', this.state.validatorsDetails);
        console.log('uploadFiles|SCHEMA:', this.state.schema);
        console.log('uploadFiles|SCHEMADETAILS:', this.state.schemaDetails);

        const schemaList = item.schemaList;
        const schemaObject = schemaList[this.state.schema];

        this.state.validatorsDetails.forEach((validators, validatorsIndex) => {
          if (validators.name === this.state.validator) {


            const schemaName = Object.keys(schemaObject)[0];
            const url = validators.url + '/validate';

            console.log('uploadFiles|schemaName:', schemaName);
            console.log('uploadFiles|url:', url);

            if (!Object.keys(this.state.fileItems).length > 0) {
              return;
            }
            this.setState({ uploadProgress: {}, uploading: true });
            const promises = [];
            var processing = this.state.processingFiles;

            for (var id in this.state.fileItems) {
              promises.push(this.sendRequest(id, this.state.fileItems[id], url, schemaName));
              processing.push(id);
              this.setState({ processingFiles: processing });
            }

            Promise.all(promises)
              .then(() => {
                this.setState({
                  successfulUpload: true,
                  uploading: false,
                  validated: true,
                });
              })
              .catch((e) => {
                console.log("Error when uploading files", e);
              });
          }
        });

      }

    });

  }

  deleteFile(fileId) {
    var fileItems = this.state.fileItems;

    for (var id in fileItems) {
      if (id === fileId) {
        delete fileItems[id];
      }
    }
    this.setState({ fileItems: fileItems });
  }

  sendRequest(id, file, url, schemaName) {

    console.log('sendRequest|url: ', url);

    const buildReportFromResponse = (res) => {
      //console.log('sendRequest|res:', res);
      if (res.message) {
        //const xmlMessage = Base64.decode(res.message)
        // console.log(xmlMessage);
        //var jsonMessage = new Validate().generateJSONReport(xmlMessage);
        //console.log('REPORT|jsonMessage:', jsonMessage);
        //return <Report report={jsonMessage} />
      }

      //ValidationServicesReport
      if (res.body) {
        //console.log('sendRequest|buildReportFromResponse|validator:' + this.state.validator + '|res:', res);
        if (this.state.validator === 'HL7 Validator') {

          const mappedResponse = new HL7NISTIGAMTResponseMapper().generateJSONReport(res.body);
          //console.log('sendRequest|buildReportFromResponse|mappedResponse:', mappedResponse);
          return <HL7NISTIGAMTValidationServicesReport show_classifications={this.state.show_classifications} report={mappedResponse} />

        } else if (this.state.validator === 'CSV Validator') {

          const mappedResponse = new CSVFlatFileResponseMapper().generateJSONReport(res.body);
          //console.log('sendRequest|buildReportFromResponse|mappedResponse:', mappedResponse);
          return <CSVValidationServicesReport show_classifications={this.state.show_classifications} report={mappedResponse} />

        } else if (this.state.validator === 'ECR Validator') {

          var jsonMessage = new EICRResponseMapper().generateJSONReport(res.body);
          return <EICRReport show_classifications={this.state.show_classifications} report={jsonMessage} />

        } else {

          try {
            var jsonMessage = new DefaultResponseMapper().generateJSONReport(res.body);
            return <DefaultReport show_classifications={this.state.show_classifications} report={jsonMessage} />
          } catch(ex) {
            //Last resort - pure raw data
            const resp = { rawData: JSON.stringify(res, undefined, 4) }
            return <RawReport {...resp} />
          }
        }
      }

      //Last resort - pure raw data
      const resp = { rawData: JSON.stringify(res, undefined, 4) }
      return <RawReport {...resp} />

    }

    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.responseType = "json";

      const updateReports = this.updateReports;


      req.onreadystatechange = function () {
        //Call a function when the state changes.
        if (req.readyState === 4 && req.status === 200) {
          resolve(updateReports(id, buildReportFromResponse(req.response)));
        }
      };

      req.open("POST", url);

      //req.setRequestHeader('Access-Control-Allow-Origin', 'http://localhost:3000');
      req.setRequestHeader('Access-Control-Allow-Origin', '*');
      req.setRequestHeader('Content-Type', 'application/json');
      req.setRequestHeader('schema_name', schemaName);
      req.setRequestHeader('include_results', true);

      var promise = this.getBase64(file);
      promise.then(function (result) {
        console.log('sendRequest|getBase64|result', result);
        var array = result.split(',')
        result = array[1];
        const requestBody = {
          'status': 200,
          'headers': { 'schema_name': schemaName, 'include_results': true },
          'body': result
        }

        //req.send(formData);
        req.send(JSON.stringify(requestBody));
      });

    });
  }

  updateReports(fileId, report) {
    var processing = this.state.processingFiles;
    var index = processing.indexOf(fileId);
    processing.splice(index, 1);
    var reports = this.state.reports;
    reports[fileId] = report;
    this.setState({
      reports: reports,
      processingFiles: processing,
    }, () => {
      this.toggleReport(fileId)
    });
    return reports;
  }

  toggleReport(fileId) {
    var showReports = this.state.showReports;
    if (showReports.includes(fileId)) {
      const index = showReports.indexOf(fileId);
      showReports.splice(index, 1);
    } else {
      showReports.push(fileId);
    }
    this.setState({ showReports: showReports });
  }

  saveToLocalStorage() {
    const { schema, validator, showAdvanced } = this.state;
    window.localStorage.setItem(
      "validation-online-portal-state",
      JSON.stringify({ schema, validator, showAdvanced })
    );
  }

  resetDropzone(e) {
    this.setState({
      fileItems: {},
      reports: {},
      showReports: [],
      processingFiles: [],
      xmlDocs: {},
      uploading: false,
      successfulUpload: false,
      validated: false,
    });
  }

  /**
   * Render 
   */
  render() {

    const schemaDetails = this.state.schemaDetails;

    return (

      <div>
        <div className="Alert-Message">
          {this.state.alertDetails && this.state.alertDetails.header && (
            <AlertDisplay
              alertDetails={this.state.alertDetails}
            />
          )}
          {console.log('MAIN ALERT', this.state.alertDetails)}
        </div>

        <div className={this.cardClassName()}>

          <div className="Card-Container">
            <Gear style={{ position: 'absolute', top: "5px", right: "20px", zIndex: 3 }} showAdvanced={this.state.showAdvanced} setShowAdvanced={this.setShowAdvanced} />
            <div className="Card-Shadow"></div>
            <div
              className={`Card-Face${Object.keys(this.state.fileItems).length > 0 ? "" : " Card-Fixed"
                }`}
              ref={(cardFace) => (this.cardFace = cardFace)}
            >
              <Header
                schemaDetails={schemaDetails}
                validatorMenuItems={this.state.validatorMenuItems}
                schema={this.state.schema}
                validatorsDetails={this.state.validatorsDetails}
                validator={this.state.validator}
                updateValidator={this.updateValidator}
                updateSchema={this.updateSchema}
                validatorsSchemaLists={this.state.validatorsSchemaLists}
                show_classifications={this.state.show_classifications}
                toggleShowClassifications={this.toggleShowClassifications}
                showAdvanced={this.state.showAdvanced}
              />
              {Object.keys(this.state.fileItems).length > 0 ? (
                <FileList
                  fileItems={this.state.fileItems}
                  reports={this.state.reports}
                  processingFiles={this.state.processingFiles}
                  xmlDocs={this.state.xmlDocs}
                  deleteFile={this.deleteFile}
                  uploading={this.state.uploading}
                  showReports={this.state.showReports}
                  handleDropdownToggle={this.toggleReport}
                />
              ) : (
                <Dropzone
                  onFilesAdded={this.onFilesAdded}
                  disabled={this.state.uploading}
                />
              )}
              <div className="Card-Footer">
                {this.state.processingFiles.length > 0 ||
                  Object.keys(this.state.reports).length > 0 ? (
                  <ResetDropzoneButton reset={this.resetDropzone} />
                ) : (
                  <ValidateDropzoneButton
                    disabled={
                      this.state.processingFiles.length > 0 ||
                      Object.keys(this.state.reports).length > 0
                    }
                    uploadFiles={this.uploadFiles}
                  />
                )}
                {Object.keys(this.state.fileItems).length > 0 &&
                  !(
                    this.state.processingFiles.length > 0 ||
                    Object.keys(this.state.reports).length > 0
                  ) ? (
                  <ClearDropzoneButton reset={this.resetDropzone} />
                ) : (
                  ""
                )}
                
                <RefreshSchemasButton refreshSchemas={this.refreshSchemas} />
                <img src={FooterLogo} alt="Footer Logo" />

              </div>
              <p className="Warning-Message">
                Please do not use patient information - this is for validating
                test data only. <br />
                Actual patient information should not be submitted to this site.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default ValidationCard;